import { customElement, property } from "lit/decorators.js";
import { html, css, LitElement } from "lit";
import Highcharts from 'highcharts/highcharts';
import STRINGS from '~/lang/strings_el.json';
import Utils from '~/utils.js';
import addMore from 'highcharts/highcharts-more';
import fontAwesome from '~/modules/fontawesome/css/fontawesome.js';
import style from '~/modules/css.js';
import * as XLSX from "xlsx";

// import smartElemenets from '~/modules/smartelements/smartelements.css.js';
// import 'smart-webcomponents/source/smart.elements.js';
// //import Smart from 'smart-webcomponents/source/components/smart.ui.grid.js';

// //require('smart-webcomponents//source/styles/smart.default.css');

addMore(Highcharts);

@customElement("op-waterfall")
export class OpWaterfall extends LitElement {        
    static get properties() {
        return {
            classes: { type: Object },
            data: {type: Array, attribute: true, reflect: true }
        }
    }
        
    static styles = [fontAwesome,style];
    constructor(){
        super();
        this.classes = { wrapper: true, switchedView: false };
        this.data=[];
    }
    firstUpdated(changedProperties) {
        this.fetchData();
    }

    fetchData() {
        fetch('https://2027.anaptyxi.gov.gr/GetData.ashx?chart=op_cumulative')
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.errMsg) {
                    throw new Error(STRINGS.ERRORS.NETWORK_ERROR);
                }else{
                    this.data=data.sort((a,b)=>(a.Titlos>b.Titlos?1:-1));
                    this._enableChart();
                    this.initGrid();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    initGrid(){
        let grid = this.shadowRoot.querySelector("#grid");
        grid.options={
            data: this.data,
            info: false,
            paging: false,
            searching: false,
            columns: [
                {data: 'Titlos', title: STRINGS.CHARTS.OPROGRAMMES_WATERFALL.XCOLUMN},
                {data: 'Sinolo', width: Utils.amountColWidth,title: STRINGS.CHARTS.OPROGRAMMES_WATERFALL.AMOUNT_TOTAL, className: 'dt-body-right',type: 'num-fmt', render: function (data, type, row) {
                    return type==="sort"?data:data.toLocaleString('el-GR')+" €";
                }
            }
            ]
        };
    }
  
    render(classes) {
        console.log('render waterfall');
        return Utils.getChartContainer.call(this,this.data,STRINGS.CHARTS.OPROGRAMMES_WATERFALL.TITLE);
    }  
    
    _switchView(){
        this.classes.switchedView=!this.classes.switchedView;
        this.shadowRoot.querySelector(".wrapper").classList.toggle("switchedView");
    }
    
    _download(){
        let filename='Export.xlsx';
        let exportData = this.data.map(a=>{
            let item={};
            item[STRINGS.CHARTS.OPROGRAMMES_WATERFALL.XCOLUMN]=a.Titlos;
            item[STRINGS.CHARTS.OPROGRAMMES_WATERFALL.AMOUNT_TOTAL]=a.Sinolo;
            return item;
        });
        var ws = XLSX.utils.json_to_sheet(exportData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb,filename);

    }

    _enableChart() {
        Highcharts.chart(
            this.shadowRoot.querySelector('#container'), {
                chart: {
                    type: 'waterfall',
                },
                colors: Utils.getHighchartColors(),
                title: {
                    text: undefined
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    type: 'category'
                },
                yAxis: {
                    title: {
                        text: STRINGS.CHARTS.OPROGRAMMES_WATERFALL.COLS_YTITLE
                    }
                },
                legend: {
                    enabled: false,
                },
                series: [{
                    name: STRINGS.CHARTS.OPROGRAMMES_WATERFALL.AMOUNT_TOTAL,
                    data: this.data.map((a)=>{
                        return {
                            name: a.Titlos,
                            y: a.Sinolo/1000000000   
                        }
                    }),
                    showInLegend: true,
                    colorByPoint: true
                }],
                //series: sObject,
                plotOptions: {
                    series: {
                        states: {
                            inactive: {
                                opacity: 1
                            }
                        },
                        tooltip: {
                            headerFormat: "<div class='hTooltipHeader'>{point.key}</div><br><br>",
                            pointFormatter: function() {
                                let x = Utils.round(this.value,2);
                                return "<div class=hTooltipBody'>"+this.series.name+': <b>'+Utils.round(this.y,2).toLocaleString('el-GR')+" "+STRINGS.CHARTS.OPROGRAMMES_WATERFALL.COLS_YTITLE+"</b></div>";
                            },
                            useHTML: true
                        },
                        borderRadius: 5
                    }
                },
                
        });
      }
}